<template>
    <header>
        <loading
            :active.sync="isLoading"
            background-color="rgba(0, 0, 0, 0.6)"
            loader="dots"
            color="rgb(36, 170, 230)"
            :is-full-page="true"
        />
        <!-- Logo And Details -->
        <div class="logo-and-details-wrapper col-md-6 col-xs-12 text-center">
            <div class="logo-block">
                <div class="row justify-content-md-center">
                    <div class="col-md-8">
                        <router-link :to="{name: 'Login'}" href="#">
                            <img class="logo" src="../../assets/images/logo.png" alt="logo">
                            <div class="clearfix"></div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div> <!-- end of logo and details -->
        <!-- First Half Pane -->
        <div class="first-half-pane float-lg-left">
            <div class="first-half-pane-content">
                <router-view></router-view>
            </div>
        </div> <!-- end of first half pane -->
        <!-- Second Half Pane -->
        <div class="second-half-pane float-lg-right">
            <div class="cover"></div>
            <div class="second-half-pane-content">
                <div>
                    <h2>
                        Портал
                        для заказа
                        маркетинговой
                        продукции
                    </h2>
                </div>
            </div>
        </div> <!-- end of second half pane -->
    </header>
</template>

<script>
import {mapGetters} from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "Account",
    components: {
        Loading
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['isLoading'])
    },
    metaInfo() {
        return {
            title: `Кабинет - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped>

body,
html {
    width: 100%;
    height: 100%;
}

/**********************/
/*     03. Header     */
/**********************/
header {
    position: relative;
    text-align: center;
}

header .logo-and-details-wrapper {
    position: absolute;
    right: 0;
    left: 0;
    margin-bottom: 0;
}

header .logo {
    float: left;
    margin-top: 3.75rem;
    width: 146px;
    height: 48px;
}

header .logo-text {
    display: inline-block;
    margin-top: 3.5rem;
    margin-bottom: 1rem;
    font: 700 3rem/2rem "Raleway", sans-serif;
    text-decoration: none;
}

header .contact-details {
    margin-right: 0.5rem;
}

header .contact-details .fa {
    margin-right: 0.5rem;
    margin-left: 0.75rem;
    color: #fff;
}

header .list-inline-item {
    opacity: 0.8;
}

header .list-inline-item:hover {
    opacity: 1;
}

header .first-half-pane {
    padding-top: 12rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
    padding-left: 1rem;
    background-color: #000;
}

header h1 {
    margin-bottom: 0.75rem;
}

header .p-under-header {
    margin-bottom: 1.5rem;
}

header .countdown {
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    color: #fff;
    text-align: center;
}

header .countdown #clock .counter-number {
    display: inline-block;
    width: 6rem;
    height: 6rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 1.25rem;
    padding-top: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    font: 600 2.5rem/18px "Open Sans", sans-serif;
    text-align: center;
}

header .countdown #clock .separator {
    margin-top: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    display: inline-block;
    height: 2rem;
    vertical-align: top;
}

header .countdown #clock .timer-text {
    font: 300 0.75rem/1rem "Open Sans", sans-serif;
    text-align: center;
}

header .second-half-pane {
    padding-top: 4rem;
    padding-right: 1rem;
    padding-bottom: 7rem;
    padding-left: 1rem;
    background: url("../../assets/images/sign-back.jpg");
    background-size: auto 100%;
    position: relative;
}

.right-side {
    margin-top: 233px;
}

.right-side p {
    width: 332px;
    height: 176px;
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.cover {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#4a4a4a, #4a4a4a);
    background-blend-mode: multiply;
    opacity: 0.6;
    position: absolute;
    margin-left: -16px;
    top: 0px;
    z-index: 0;
}

.portal-div {
    margin-top: 80px;
}

.portal-div p {
    width: 67px;
    height: 15px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.second-half-pane-content {
    position: relative;
    z-index: 1;
}

.second-half-pane-content h2 {
    width: 332px;
    height: 176px;
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-transform: uppercase;
    text-align: left;
    color: #ffffff;
}

/*****************************/
/*     04. Media Queries     */
/*****************************/

/* Max-width width 576px */
@media (max-width: 576px) {
    header .logo {
        margin-top: 1.25rem;
    }

    header .first-half-pane {
        padding-top: 6rem;
    }

    .reset-password {
        float: right;
    }

    .reset-password-div {
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .second-half-pane-content h2 {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 28px;
    }

    .first-half-pane,
    .second-half-pane {
        min-height: 50vh;
    }
}

/* Min-width width 768px */
@media (min-width: 768px) {
}

/* Min-width width 992px */
@media (min-width: 992px) {
    h1 {
        font: 700 3.25rem/3.75rem "Raleway", sans-serif;
    }

    header {
        position: relative;
        width: 100%;
        height: 100vh;
        text-align: left;
    }

    header .first-half-pane {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 100vh;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .logo-block {
        max-width: 31rem;
        margin-right: 3rem;
        margin-left: auto;
    }

    header .first-half-pane-content {
        width: 100%;
        max-width: 26.5rem;
        margin-right: 1.5rem;
        margin-left: auto;
    }

    header .countdown {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        text-align: left;
    }

    header .countdown #clock .counter-number {
        margin-right: 0.75rem;
        margin-left: 0;
        margin-bottom: 0;
    }

    header .countdown #clock span.counter-number:last-of-type {
        margin-right: 0;
    }

    header .p-under-header {
        width: 100%;
    }

    header .second-half-pane {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 100vh;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    header .second-half-pane-content {
        width: 26rem;
        margin-left: 2rem;
    }
}

/* Min-width width 1200px */
@media (min-width: 1200px) {
    header .first-half-pane-content {
        max-width: 31rem;
        margin-right: 3rem;
        margin-left: auto;
    }

    header .second-half-pane-content {
        margin-left: 6rem;
    }
}

</style>
